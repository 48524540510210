import * as React from 'react'
import Button from '../components/Button'
import Hero from '../components/Hero'
import Seo from '../components/SEO'
import proreader from '../images/uploads/proreader.png'
import kelena from '../images/uploads/kelana.png'
import kelena2 from '../images/uploads/kelana2.png'
import proreader2 from '../images/uploads/proreader2.png'
import bg_hero from '../images/uploads/bg-about.svg'
import proreader_icon from '../images/logos/proreader_logo.png'
// import vue from "../images/logos/vue.png";
// import ruby from "../images/logos/ruby.svg";
// import rails from "../images/logos/rails.svg";
// import graphql_icon from "../images/logos/graphql.svg";
import Link from '../components/Link'
import { graphql, useStaticQuery } from 'gatsby'

import { useState } from 'react'
import Axios from 'axios'
import parse from 'html-react-parser'

const fs_url = process.env.GATSBY_FORM_SERVER
const main_url = process.env.GATSBY_MAIN_URL
//alert(fs_url);

const OpenSourcePage = () => {
  // const posts = [
  //   {
  //     title: "8 Ways to Find New Software Business Ideas",
  //   },
  // ];
  const data = useStaticQuery(graphql`
    query {
      buildings: allBuilding(limit: 30, sort: { fields: [date], order: DESC }) {
        nodes {
          id
          title
          description
          slug
          date(formatString: "DD MMMM, YYYY")
          image {
            childImageSharp {
              gatsbyImageData(layout: CONSTRAINED, aspectRatio: 1.5)
            }
          }
        }
      }
    }
  `)

  const buildings = data.buildings.nodes
  const latest = buildings.slice(0, 3)
  const related = buildings.slice(3)

  //form logic
  const [apiResponse, setApiResponse] = useState('')
  const [formInputs, setFormInputs] = useState({
    email: '',
  })

  const inputChangedHandler = (e) => {
    //updating inputs
    const updatedInputs = {
      ...formInputs,
      //[key] : value
      [e.target.id]: e.target.value,
    }
    //set the state
    setFormInputs(updatedInputs)
  }

  const formSubmitHandler = async (e) => {
    e.preventDefault()
    const { email } = formInputs
    //alert(`user = ${formInputs.email}`);
    // const json_obj = JSON.stringify(formInputs)
    //validating
    if (email) {
      //make api call
      await Axios({
        method: 'POST',
        url: `${fs_url}/formserver.php?tab=subscribe`,
        data: {
          main_url: main_url,
          formInputs,
        },
        headers: {
          'Access-Control-Allow-Origin': '*',
          'Accept': 'application/json',
          //'Content-Type': 'application/json',
        },
      })
        .then((resp) => {
          //alert(resp.data);
          //console.log(resp.data.api_response);
          const api_res = resp.data.api_response
          if (api_res) {
            setApiResponse(api_res)
          }
        })
        .catch((err) => {
          //console.log(err);
          alert(err)
        })
    } else {
      alert('Please Enter All Data')
    }
  }

  return (
    <>
      <Seo title="Building" />
      <Hero className="text-center">
        <div className="max-w-5xl">
          <h1 className="typo-h2 mb-4 leading-relaxed">
            We are building in public - so you learn copy, adapt and do it even
            better.
          </h1>

          <p className="typo-normal max-w-4xl mx-auto">
            We are creators. We cannot help it. We asked ourselves: what if we
            share the whole software creation process from A - Z. Follow us on
            the journey of building our own app and realasing it to the public.
          </p>

          <form
            className="flex items-baseline max-w-2xl  mx-auto"
            onSubmit={formSubmitHandler}
          >
            <div>{apiResponse ? parse(apiResponse) : ''}</div>

            <div className="w-full mx-3 my-4">
              <input
                type="email"
                placeholder="Email address"
                className="px-3 py-3 placeholder-blueGray-300 text-blueGray-600 relative bg-white rounded text-sm border-0 shadow outline-none focus:outline-none focus:ring w-full"
                id="email"
                name="email"
                onChange={inputChangedHandler}
                value={formInputs.email}
                required
              />
            </div>

            <Button className="w-3/4" size="normal" type="submit">
              Send Me Updates!
            </Button>
          </form>
        </div>
      </Hero>

      <section className="main-section">
        <h2 className="typo-h2 mb-4 leading-relaxed">Latest posts</h2>
        <div className="flex flex-col md:flex-row">
          {latest.map((item, index) => (
            <Link to={item.slug}>
              <div className="flex flex-col items-center justify-center md:mx-4 p-6  h-48 w-80 bg-white dark:bg-dark_bg-light border border-gray-50 shadow-md hover:shadow-xl">
                <h3 className="text-xl text-center font-bold bg-gradient-to-r bg-clip-text  text-transparent  from-blue-800 to-teal-neutral">
                  {item.title}
                </h3>
              </div>
            </Link>
          ))}
        </div>
      </section>

      <section className="main-section items-start md:ml-32">
        <h2 className="typo-h4 mb-4 leading-relaxed">Related posts</h2>
        <div>
          {related.map((item, index) => (
            <div className="flex flex-col my-4 cursor-pointer">
              <Link to={item.slug} className=" text-teal-neutral font-bold">
                {item.title}
              </Link>
              <span className="text-sm">{item.date}</span>
            </div>
          ))}
        </div>
      </section>

      {/* <section className="main-section">
        <div className="max-w-4xl flex flex-col">
          <h1 className="typo-h2 mb-4 leading-relaxed">
            We Open-Sources These Apps So You Can Check Out our Code Quality
          </h1>

          <p className="typo-normal">
            We are not the typical outsourcing company. We follow best practices
            learned in Germany. We hire only rockstars. We believe in our
            choices so much that we hire them - you can try them out for free.
          </p>
          <div className="flex items-center flex-wrap justify-center w-full p-8 md:w-full">
            <span className="bg-white p-2 mx-4 my-2 shadow-xl">
              <img src={ruby} className="w-10 h-10" alt="Vision" />
            </span>
            <span className="bg-white p-2 mx-4 my-2 shadow-xl">
              <img src={vue} className="w-10 h-10" alt="Vision" />
            </span>
            <span className="bg-white p-2 mx-4 my-2 shadow-xl">
              <img src={rails} className="w-10 h-10" alt="Vision" />
            </span>
            <span className="bg-white p-2 mx-4 my-2 shadow-xl">
              <img src={graphql_icon} className="w-10 h-10" alt="Vision" />
            </span>
          </div>
        </div>
      </section> */}
      <section className="main-section">
        <h1 className="typo-h2">Previous products we built</h1>
      </section>
      <section className="main-section">
        <div className="shadow-2xl flex justify-between items-center dark:dark_bg-normal py-4 px-8">
          <div className="mr-4">
            <span className="font-bold text-2xl">Kelana.io </span>
            <span className="block text-xs">Efficient Time Tracking</span>
          </div>

          <div className="ml-8">
            <Link
              to="https://gitlab.com/pairing/kelana"
              variant="primary"
              type="btn"
              size="medium"
              className="my-4 block"
            >
              Source Code
            </Link>

            <Link
              to="https://kelana.io"
              variant="primary"
              type="btn"
              size="medium"
              className="my-4 block"
            >
              Visit kelana.io
            </Link>
          </div>
        </div>
      </section>
      <section className="row-section">
        <div className="flex flex-col max-w-md mt-6">
          <h1 className="typo-h3">Efficient time tracking</h1>
          <p>
            Many agencies build time tracking systems, because the use case is
            small and you can get an internal tool up fast. But why build
            another one? Back then we needed a certain integration for a client
            system called Moco. That’s why we decided to code it ourselves, and
            thus benefit from the possibility to change it to our needs.
          </p>
        </div>

        <div className="my-4 max-w-md">
          <img src={kelena} alt="Kelena Screenshot" />
        </div>
      </section>

      <section className="row-section">
        <div className="my-4 max-w-md">
          <img src={kelena2} alt="Kelena Screenshot" />
        </div>
        <div className="flex flex-col max-w-md mt-6">
          <h1 className="typo-h3">Built with hotwire.dev</h1>
          <p>
            When we build kelana we needed it, and we needed it fast. Thus we
            build it with the simplest components that came out of the box:
            Rails 6 Hotwire.dev Postgresql on heroku Stimulus.js
          </p>
        </div>
      </section>
      <section className="main-section relative py-20">
        <h1 className="typo-h3 text-center max-w-5xl z-10">
          Subscribe to get 1-2 emails per month about how we build software and
          manage teams. Your email is safe with us.
        </h1>
        <div className="flex items-baseline max-w-3xl z-10">
          <div className="w-full mx-3 my-4">
            <input
              type="text"
              placeholder="Email address"
              className="px-3 py-3 placeholder-blueGray-300 text-blueGray-600 relative bg-white rounded text-sm border-0 shadow outline-none focus:outline-none focus:ring w-full"
            />
          </div>
          <Button className="w-full" size="normal">
            Subscribe Me To Updates
          </Button>
        </div>
        <div className="hidden md:block absolute w-full top-0 z-0 bg-gradient-to-b from-white dark:from-dark_bg-normal via-blue-50 dark:via-dark_bg-light to-white dark:to-dark_bg-normal">
          <img src={bg_hero} alt="pattern" className="" />
        </div>
      </section>

      <section className="row-section">
        <div className="flex flex-col max-w-md mt-6">
          <h1 className="typo-h3">Reading is hard - let’s make it easy</h1>
          <p>
            With proreader we were curious about what could be done to improve
            reading Vietnamese fast. Normal reader apps did not support the
            nature of Vietnamese to combine words with multiple syllables.
          </p>
        </div>
        <div className="my-4 max-w-md">
          <img src={proreader} alt="Kelena Screenshot" />
        </div>
      </section>

      <section className="main-section bg-gradient-to-b from-white dark:from-dark_bg-normal via-blue-50 dark:via-dark_bg-light to-white dark:to-dark_bg-normal">
        <div className="shadow-2xl dark:bg-dark_bg-light flex flex-col  md:flex-row justify-between items-center py-4 px-8">
          <div>
            <img className="w-12 h-12 mx-4" src={proreader_icon} alt="" />
          </div>
          <div className="mr-4">
            <span className="font-bold text-2xl">proreader.io </span>
            <span className="block text-xs">
              Improve Foreign Language Reading
            </span>
          </div>

          <div className="ml-8">
            <Link
              to="https://gitlab.com/pairing/proreader"
              variant="primary"
              type="btn"
              size="medium"
              className="my-4 block"
            >
              Source Code
            </Link>
            <Link
              to="https://proreader.io"
              variant="primary"
              type="btn"
              size="medium"
              className="my-4 block"
            >
              Visit proreader.io
            </Link>
          </div>
        </div>
      </section>

      <section className="row-section">
        <div className="flex flex-col max-w-md mt-6">
          <h1 className="typo-h3">Built with Vuejs</h1>
          <p>
            We knew that the frontend is the key here. It needed to be
            responsive, cache previously clicked words, and also give the option
            of throwing in any text you have in the clipboard. Thus, we used
            cutting-edge packages, like Rails 6 Vuejs Postgresql on heroku
            Graphql
          </p>
        </div>
        <div className="my-4 max-w-md">
          <img src={proreader2} alt="Kelena Screenshot" />
        </div>
      </section>

      <section className="main-section relative py-24 ">
        <h1 className="typo-h3 text-center max-w-4xl z-10">
          We are constantly improving these projects through the work of our
          Scrum teams. Join us for a standup!
        </h1>
        <div className="flex flex-col items-center my-8 z-20">
          <Link
            className="mt-8"
            variant="primary"
            to="/join-standup"
            type="btn"
            size="extra"
          >
            See how our Scrum Team Code
          </Link>
        </div>
        <div
          className="hidden md:block absolute w-full top-0 bg-gradient-to-b from-white dark:from-dark_bg-normal via-blue-50 dark:via-dark_bg-light to-white dark:to-dark_bg-normal"
          style={{ zIndex: '-1' }}
        >
          <img src={bg_hero} alt="pattern" className="" />
        </div>
      </section>
    </>
  )
}

export default OpenSourcePage
